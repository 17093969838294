<template>
  <v-app>
    <body>
      <v-container class="white-bg mt-48px">
      <div class="text-xs-center">
        <h4 class="navy-text">PAYMENT</h4>
        <div class="subtitle">การชำระเงิน</div>
        <v-divider></v-divider>
        <v-content>
          <div class="ct-mw">
            <!-- Section 1 -->
            <v-layout row>
              <v-flex xs6>
                <div class="caption text-xs-left"><br/>
                  <p><strong>สรุปรายการสั่งซื้อ</strong></p>
                </div>
              </v-flex>
              <v-flex xs6>
                <div class="caption text-xs-right"><br/>
                  <!-- <p>Order ID : {{ orderId }} </p> -->
                </div>
              </v-flex>
            </v-layout>
            <div class="section">
              <v-layout wrap>
                <v-flex md9 xs8>
                  <div class="caption text-xs-left"><br/>
                    <p><strong>Product Name</strong></p>
                    <span class="detail-text">{{ productName }}</span><br/><br/>
                  </div>
                </v-flex>
                <v-flex md3 xs4>
                  <div class="caption text-xs-right"><br/>
                    <p><strong>Price</strong></p>
                    <span class="detail-text">{{ this.getPrice() }} บาท</span>
                  </div>
                </v-flex>
              </v-layout>
              <!-- Check Member Promotion -->
              <div v-if="price > 0">
                <div v-if="isMember">
                  <v-radio-group v-model="radiosMember" :mandatory="false">
                    <v-radio
                      class="caption"
                      label="ส่วนลด Achieve Member"
                      value="memberSelected">
                    </v-radio>
                    <v-radio
                      label="ใส่ Promotion Code"
                      value="promotionSelected">
                    </v-radio>
                  </v-radio-group>
                  <div v-if="radiosMember=='promotionSelected'">
                    <v-layout wrap>
                      <v-flex md8 xs8>
                        <v-text-field
                          v-model="promotion"
                          label="Promotion Code"
                          :hint="promoHint"
                          solo
                        ></v-text-field>
                      </v-flex>
                      <v-flex md2 xs4>
                        <v-btn @click="apply" dark><strong>APPLY</strong></v-btn>
                      </v-flex>
                      <v-flex md2></v-flex>
                    </v-layout>
                  </div>
                </div>
                <div v-else-if="isEarly">
                  <v-radio-group v-model="radiosEarly" :mandatory="false">
                    <v-radio
                      class="caption"
                      label="ส่วนลด Early Bird"
                      value="earlySelected">
                    </v-radio>
                    <v-radio
                      label="ใส่ Promotion Code"
                      value="promotionSelected">
                    </v-radio>
                  </v-radio-group>
                  <div v-if="radiosEarly=='promotionSelected'">
                    <v-layout wrap>
                      <v-flex md8 xs8>
                        <v-text-field
                          v-model="promotion"
                          label="Promotion Code"
                          :hint="promoHint"
                          solo
                        ></v-text-field>
                      </v-flex>
                      <v-flex md2 xs4>
                        <v-btn @click="apply" dark><strong>APPLY</strong></v-btn>
                      </v-flex>
                      <v-flex md2></v-flex>
                    </v-layout>
                  </div>
                </div>
                <div v-else>
                  <v-layout wrap>
                    <v-flex md8 xs8>
                      <v-text-field
                        v-model="promotion"
                        label="Promotion Code"
                        :hint="promoHint"
                        solo
                      ></v-text-field>
                    </v-flex>
                    <v-flex md2 xs4>
                      <v-btn @click="apply" dark><strong>APPLY</strong></v-btn>
                    </v-flex>
                    <v-flex md2></v-flex>
                  </v-layout>
                </div>
                <!-- End of Check Member Promotion -->
                <div class="caption text-xs-right">
                  <strong>Discount</strong> {{ showDiscount() }} บาท
                </div>
              </div>
              <v-layout row class="bg-grey">
                <v-flex xs6 class="caption text-xs-left">
                  <strong>Total</strong>
                </v-flex>
                <v-flex xs6 class="caption text-xs-right">
                  <strong>{{ this.total() }} บาท</strong>
                </v-flex>
              </v-layout>
            </div>
            <!-- Section 2 -->
            <div v-if="price > 0">
              <v-layout row>
                <v-flex xs-6>
                  <div class="caption text-xs-left"><br/>
                    <p><strong>ท่านต้องการใบกำกับภาษีหรือไม่</strong></p>
                  </div>
                </v-flex>
              </v-layout>
              <div class="section">
                <v-layout row>
                  <v-radio-group v-model="radioTax" :mandatory="false">
                    <v-radio
                      class="caption"
                      label="ไม่ต้องการ (ท่านจะได้รับใบกำกับภาษีอย่างย่อ
                      และยอมรับไม่สามารถเปลี่ยนเป็นใบกำกับภาษีเต็มรูปได้ทุกกรณี
                      สามารถดาวน์โหลดได้ที่ Profile > Payment)"
                      value="notNeed"></v-radio>
                    <v-radio
                      label="ต้องการ (ใบกำกับภาษีจะถูกจัดส่งไปตามชื่อที่อยู่ที่ท่านกรอกข้อมูล)"
                      value="need"></v-radio>
                  </v-radio-group>
                </v-layout>
              </div>
            </div>
            <!-- Section 3 : Tax invoice -->
            <div v-if="radioTax=='need'">
              <v-layout row>
                <v-flex xs-6>
                  <div class="caption text-xs-left"><br/>
                    <p><strong>ข้อมูลการออกใบกำกับภาษี</strong></p>
                  </div>
                </v-flex>
              </v-layout>
              <div class="section">
                <v-layout row>
                  <div class="caption text-xs-left"><br/>
                  <p>ชื่อ-ที่อยู่ในการออกใบกำกับภาษี</p>
                  </div>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="nameth"
                      label="ชื่อ/นามบริษัท"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="taxid"
                      label="เลขประจำตัวผู้เสียภาษี / เลขบัตรประชาชน"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="tel"
                      label="เบอร์โทรศัพท์"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <br/>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="address"
                      label="ที่อยู่ (เลขที่ห้อง บ้านเลขที่ อาคาร หมู่บ้าน หมู่ที่ ซอย ถนน)"
                      outline
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <thai-address-input
                      class="v-text-field border-bottom"
                      type="search"
                      v-model="searchAddressTax"
                      @selected="onSelectedTax"
                      input-class="v-input"
                      placeholder="ค้นหาที่อยู่อัตโนมัติ"
                    ></thai-address-input>
                  </v-flex>
                </v-layout>
                <br>
                <v-layout row wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      label="ตำบล / แขวง"
                      v-model="subdistrict"
                      required
                      :disabled="radioTax === 'need'"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      label="อำเภอ / เขต"
                      v-model="district"
                      required
                      :disabled="radioTax === 'need'"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      type="province"
                      label="จังหวัด"
                      v-model="province"
                      required
                      :disabled="radioTax === 'need'"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      label="รหัสไปรษณีย์"
                      v-model="postalCode"
                      :disabled="radioTax === 'need'"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </div>
              <!-- Post tax -->
              <div>
                <v-layout row>
                  <v-flex xs-6>
                    <div class="caption text-xs-left"><br/>
                      <p><strong>ข้อมูลในการจัดส่งใบกำกับภาษี</strong></p>
                    </div>
                  </v-flex>
                </v-layout>
                <div class="section">
                  <v-layout row>
                    <div class="caption text-xs-left"><br/>
                    <p>ชื่อ-ที่อยู่ในการจัดส่ง</p>
                      <v-radio-group v-model="radioPost" :mandatory="false">
                        <v-radio
                          label="เหมือนกับชื่อที่อยู่ในการออกใบกำกับภาษี"
                          value="same"></v-radio>
                        <v-radio
                          label="ใช้ที่อยู่ใหม่"
                          value="notSame"></v-radio>
                      </v-radio-group>
                    </div>
                  </v-layout>

                  <div v-if="radioPost=='notSame'">
                    <v-layout row wrap >
                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          v-model="namethPost"
                          label="ชื่อ/นามบริษัท"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          v-model="telPost"
                          label="เบอร์โทรศัพท์"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <br/>
                    <v-layout row wrap>
                      <v-flex xs12 sm12 md12>
                        <v-text-field
                          v-model="addressPost"
                          label="ที่อยู่ (เลขที่ห้อง บ้านเลขที่ อาคาร หมู่บ้าน หมู่ที่ ซอย ถนน)"
                          outline
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12>
                        <thai-address-input
                          class="v-text-field border-bottom"
                          type="search"
                          v-model="searchAddressPost"
                          @selected="onSelectedPost"
                          input-class="v-input"
                          placeholder="ค้นหาที่อยู่อัตโนมัติ"
                        ></thai-address-input>
                      </v-flex>
                    </v-layout>
                    <br>
                    <v-layout row wrap>
                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          label="ตำบล / แขวง"
                          v-model="subdistrictPost"
                          required
                          disabled
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          label="อำเภอ / เขต"
                          v-model="districtPost"
                          required
                          disabled
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          type="province"
                          label="จังหวัด"
                          v-model="provincePost"
                          required
                          disabled
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          label="รหัสไปรษณีย์"
                          v-model="postalCodePost"
                          disabled
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </div>
                  <div v-else></div>
                </div>
              </div>
            </div>
            <div v-else></div>
            <!-- Checkbox accept terms of service -->
            <div>
              <v-checkbox
                v-if="!submit"
                v-model="checkbox"
                :error-messages="checkboxErrors"
                label="ข้าพเจ้ายอมรับข้อกำหนดในการให้บริการ
                และได้ตรวจสอบข้อมูลอย่างถูกต้องทุกประการแล้ว"
                required
              ></v-checkbox>
              <div class="detail-text text-xs-left">
                <u>หมายเหตุ</u> หากต้องการออกใบกำกับภาษีเต็มรูปในนามบริษัท
                หรือจะขอคืนเงินภาษีหัก ณ ที่จ่าย
                (ค่าธรรมเนียมการโอนเงินคืนเป็นความรับผิดชอบของลูกค้า)
                โปรดติดต่อ <a href="mailto:billing@achieve.plus">billing@achieve.plus</a>
                เพื่อแจ้งความจำนงพร้อมแนบเอกสาร (หนังสือรับรองบริษัท / ภพ.20 /
                หนังสือรับรองการหักภาษี ณ ที่จ่าย / สำเนาหน้าสมุดบัญชีธนาคาร /
                ใบยืนยันการชำระเงิน) มาทางอีเมลภายในวันที่ซื้อ
                และจัดส่งเอกสารภายใน 3 วันทำการเท่านั้น นับจากวันที่ชำระเงิน
              </div>
              <br/>
              <div v-if="checkbox">
                <v-btn v-on:click="next()" dark>NEXT</v-btn>
              </div>
              <div v-else>
                <v-btn disabled >NEXT</v-btn>
              </div>
            </div>
          </div>
        </v-content>
      </div>
      </v-container>
    </body>
  </v-app>
</template>

<script>
import numeral from 'numeral';
import payment from '../../library/payment';
import payments from '../../firebase/firestore/payments';
import workshops from '../../firebase/firestore/workshops';
import userServices from '../../firebase/firestore/users';

const { errorMsg } = require('../../static/errorMessage');

export default {
  data: () => ({
    orderId: 'ODP1905000010',
    productName: '',
    price: '',
    promotion: '',
    promoHint: 'Enter Promotion Code',
    radio1: false,
    radio2: false,
    submit: false,
    checkbox: false,
    checkboxPost: false,
    radioPost: 'same',
    radioTax: 'notNeed',
    radiosMember: 'memberSelected',
    radiosEarly: 'earlySelected',
    selectRadio: false,
    hidden: false,
    discount: 0,
    isEditing: false,
    nameth: '',
    taxid: '',
    tel: '',
    address: '',
    subdistrict: '',
    district: '',
    province: '',
    postalCode: '',
    namethPost: '',
    telPost: '',
    addressPost: '',
    subdistrictPost: '',
    districtPost: '',
    provincePost: '',
    postalCodePost: '',
    searchAddressTax: '',
    searchAddressPost: '',
    required: [],
    isMember: false,
    checkboxErrors: '',
    msg: '',
    memberStat: '',
    isEarly: false,
    workshopData: '',
    memberLevel: '',
  }),
  async mounted() {
    this.$store.dispatch('setLoading', true);
    const { uid } = this.$store.getters.user;
    await this.$store.dispatch('getProfile', { uid });
    const { id } = this.$route.params;
    const { workshopList } = this.$store.getters.profile;
    if (!id) {
      this.$store.dispatch('setError', errorMsg['other/fallback']);
      this.$router.push('/store');
    }
    if (!workshopList) {
      const data = { workshopList: [] };
      this.$store.dispatch('setProfile', { uid, data });
    } else if (workshopList && workshopList.includes(id)) {
      this.$store.dispatch('setError', 'You already purchase this workshop.');
      this.$router.push('/store');
    }
    workshops.getWorkshop({ id })
      .then(async (workshopData) => {
        if (workshopData) {
          const {
            prices, title, ep, earlyStart, earlyEnd, participants, limit, saleEnd, saleStart,
          } = workshopData;
          const { profile } = this.$store.getters;
          this.isMember = profile.isMember || false;
          this.isEarly = payments.fbTime >= earlyStart
            && payments.fbTime <= earlyEnd && payments.fbTime < saleStart;
          if (!this.isEarly && payments.fbTime < saleStart) {
            this.$store.dispatch('setError', 'This workshop is not yet open for purchase.');
            this.$router.push('/store');
          }
          // TODO early end should validate
          if (payments.fbTime > saleEnd) {
            this.$store.dispatch('setError', 'This workshop cannot be purchased anymore.');
            this.$router.push('/store');
          }
          if (participants && participants.includes(uid)) {
            this.$store.dispatch('setError', 'You already purchase this workshop.');
            this.$router.push('/store');
          }
          if (limit === 0 || (participants && participants.length >= limit)) {
            this.$store.dispatch('setError', 'This workshop is already exceed the limitation.');
            this.$router.push('/store');
          }
          this.workshopData = workshopData;
          this.memberLevel = profile.memberLevel || '';
          this.price = prices.sale;
          this.productName = `${title} ${ep}`;

          if (!this.isMember) {
            if (this.isEarly) {
              this.discount = prices.sale - prices.earlyBird.nonMember;
            }
          } else {
            const lvl = this.memberLevel;
            this.memberStat = await userServices.getMemberDiscount({ lvl });
            if (this.isEarly) {
              this.discount = prices.sale - prices.earlyBird[lvl];
            } else {
              const memDiscount = this.memberStat.discount / 100;
              this.discount = prices.sale * memDiscount;
            }
          }
        } else {
          this.$store.dispatch('setLoading', false);
          this.$store.dispatch('setError', 'Workshop cannot be found.');
          this.$router.push('/store');
        }
        this.$store.dispatch('setLoading', false);
      })
      .catch((e) => {
        this.$store.dispatch('setLoading', false);
        this.$store.dispatch('setError', e.message);
        this.$router.push('/store');
      });
  },
  methods: {
    total() {
      return numeral(this.price - this.discount).format('0,0');
    },
    getPrice() {
      return numeral(this.price).format('0,0');
    },
    showDiscount() {
      return numeral(this.discount).format('0,0');
    },
    async apply() {
      if (this.promotion) {
        this.$store.dispatch('setLoading', true);
        await payment.applyPromotion(this.promotion.trim().toUpperCase())
          .then(async (res) => {
            if (res) {
              if (res.startDate > payments.fbTime) {
                const promoInvalidText = `Sorry, Code ${this.promotion.trim().toUpperCase()
                } is not yet open for apply.`;
                this.$store.dispatch('setError', promoInvalidText);
              } else if (res.endDate < payments.fbTime) {
                const promoInvalidText = `Sorry, Code ${this.promotion.trim().toUpperCase()} is expired.`;
                this.$store.dispatch('setError', promoInvalidText);
              } else {
                if (res.discountType === payment.PROMO_TYPE.STATIC) {
                  this.discount = res.discount;
                } else if (res.discountType === payment.PROMO_TYPE.PERCENTAGE) {
                  this.discount = this.price * (res.discount / 100);
                }
                if (this.price - this.discount < 0) {
                  this.discount = this.price;
                }
              }
            } else {
              const promoInvalidText = `Sorry, Code ${this.promotion.trim().toUpperCase()} is invalid`;
              this.$store.dispatch('setError', promoInvalidText);
              this.discount = 0;
            }
          })
          .catch(() => {
            this.$store.dispatch('setError', errorMsg['other/fallback']);
          });
        this.$store.dispatch('setLoading', false);
      } else {
        this.discount = 0;
      }
    },
    onSelectedTax(address) {
      this.subdistrict = address.subdistrict;
      this.district = address.district;
      this.province = address.province;
      this.postalCode = address.postalCode;
    },
    onSelectedPost(address) {
      this.subdistrictPost = address.subdistrict;
      this.districtPost = address.district;
      this.provincePost = address.province;
      this.postalCodePost = address.postalCode;
    },
    next() {
      const { uid } = this.$store.getters.user;
      const { id } = this.$route.params;
      const order = {
        uid,
        price: this.price,
        promotion: this.promotion,
        isMember: this.isMember,
        discount: this.discount,
        workshopId: id,
      };
      if (this.radioTax === 'need') {
        order.tax = {
          name: this.nameth,
          taxid: this.taxid,
          tel: this.tel,
          address: `${this.address} ${this.subdistrict} ${this.district} ${this.province} ${this.postalCode}`,
        };
        if (this.radioPost === 'notSame') {
          order.post = {
            name: this.namethPost,
            tel: this.telPost,
            address: `${this.addressPost} ${this.subdistrictPost} ${this.districtPost} ${this.provincePost} ${this.postalCodePost}`,
          };
        } else {
          order.post = {
            name: this.nameth,
            tel: this.tel,
            address: `${this.address} ${this.subdistrict} ${this.district} ${this.province} ${this.postalCode}`,
          };
        }
      }
      if (this.price < 20) {
        order.status = 'successful';
        this.$router.push({ name: 'complete', params: { id, orderCheckout: order } });
      } else {
        this.$router.push({ name: 'method', params: { id, orderCheckout: order } });
      }
    },
  },
  watch: {
    radiosMember(val) {
      if (val === 'memberSelected') {
        if (this.isEarly) {
          const { price, prices } = this.workshopData;
          this.discount = price - prices.earlyBird[this.memberLevel];
        } else {
          const memDiscount = this.memberStat.discount / 100;
          this.discount = this.price * memDiscount;
        }
      } else {
        this.apply();
      }
    },
    radiosEarly(val) {
      if (val === 'earlySelected') {
        const { price, prices } = this.workshopData;
        this.discount = price - prices.earlyBird.nonMember;
      } else {
        this.apply();
      }
    },
    promotion(val) {
      this.promotion = val.trim().toUpperCase();
    },
  },
};
</script>


<style lang="sass" scoped>
.ct-mw
  max-width: 960px
  margin: auto
  margin-top: -36px !important
.white-bg
  max-width: 1100px
  margin: auto
.section
  padding: 16px
  border-color: #DBDBDB
  border-radius: 5px
  border-style: solid
  margin: auto
  @media screen and (max-width: 770px)
    padding: 8px
.caption
  font-size: 1.0rem
  @media screen and (max-width: 340px)
    font-size: 0.9rem
.detail-text
  font-weight: 200
  @media screen and (max-width: 770px)
    font-size: 1.0rem
    line-height: 1.4rem
.bg-grey
  background-color: #DBDBDB
  padding-top: 4px
  padding-bottom: 4px
.border-bottom
  border-bottom-style: solid !important
  border-bottom-width: 0.5px !important
  border-bottom-color: black !important
</style>
